import {createTheme} from "@mui/material/styles";
import React from "react";

export const theme = createTheme({

    palette: {
       // mode:"dark",
     //   background: {default: "#212121", paper:"#212121"},

        primary: {
            main: '#0072bc',
            light:"#0fc8f2"
        },
        secondary: {
            light:"#96d5d2",
            main: '#2cd5b6',
            dark:"#001973"
        },
        text:{
           // primary:"#3C3C3B",
           // secondary:"#3C3C3B75"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 935,
            lg: 1200,
            xl: 1536,
        },
    },
    components:{
        MuiPaper:{
            styleOverrides: {
                root: {
             //       backgroundColor: "#2A2436"
                }
            }
        },
        MuiBackdrop:{
            styleOverrides: {
                root: {
                    backgroundColor: "#191C2280"
                }
            }
        },
        MuiIconButton:{
            styleOverrides: {
                root: {
               //     color: "#f3c4fe",
                    "&:hover":{
                       backgroundColor: "#0fc8f250"
                    },
                    "&:disabled":{
             //           backgroundColor:"#f3c4fe33"
                    }
                },

            }
        },

     },

    }


);


theme.typography.body1={
    [theme.breakpoints.up('md')]:{
        fontSize:14.5,
        lineHeight:"130%"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:17,
        lineHeight:"130%"
    },
};


theme.typography.body2={
    [theme.breakpoints.up('md')]:{
        fontSize:14.5,
        lineHeight:"130%"
    },
    [theme.breakpoints.down('md')]:{
        fontSize:16,
        lineHeight:"130%"
    },
};

/*

theme.typography.h4={
    [theme.breakpoints.up('md')]:{
        fontFamily: "'M PLUS Rounded 1c', sans-serif",
        fontSize:30,
        fontWeight:900
    },
    [theme.breakpoints.down('md')]:{
        fontFamily: "'M PLUS Rounded 1c', sans-serif",
        fontSize:28,
        fontWeight:900
    },
};

*/
