
export const green = "#00c853";
export const greenLight = "#b2ff59";
export const orange = "#ffb300";
export const red = "#bf360c";
export const grey = "#9e9e9e80";
export const iconGrey = "#9e9e9e";
export const darkGrey="#424242";
export const lightGrey = "#e0e0e0";
export const btnGrey ="#9e9e9e";

export const calGrey = "#cfcfcf";
export const calGreen = "#24A16F";
//export const calBlue = theme.palette.primary.main;
export const calOrange= "#E59237";
export const calRed = "#EC5D49";

export const bcgGrey = "#263238"
export const tagTopic = "#9575cd";
export const tagTech = "#4fc3f7";
export const tagSust="#81c784";
export const tagIndus ="#ff8a65";