import {BoxMain} from "../../StyledComponents/Boxes";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Button, IconButton, InputAdornment, Paper, Stack, TextField, Typography} from "@mui/material";
import cic from "../../Images/cic.png"
import {theme} from "../../Themes/globaltheme";
import logo from "../../Images/logo_white.png"
import i18next from "i18next";
import {AppConfig, LoginRequest} from "./LoginModels";
import {getAccessModes, login} from "./LoginAPI";
import { baseUrl, isCorrectEmail,  portalUrl} from "../../Utils/UtilsConstants";
import {useAppDispatch} from "../../hooks";
import {setAdminMode, setAuthenticated, setUser} from "../../Commons/GeneralSlice";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {iconGrey, red} from "../../Commons/Settings";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ReactComponent as MicrosoftIcon} from "../../Images/microsoft-icon.svg"
import axios from "axios";
import {useLocation} from "react-router-dom";


export default function LoginPage() {

    const [loginRequest, setLoginRequest] = useState<LoginRequest>({
        email:"",
        username:"",
        password:""
    } as LoginRequest);
    const [app, setApp] = useState<string | undefined>(undefined);
    const [config, setConfig] = useState<AppConfig|undefined>(undefined);
    const [showPsw, setShowPsw] = useState<string[]>([]); // psw, newPsw1, newPsw2
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState<string[]>([]);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const loc = useLocation();

    const controller:any = useRef();


    const initData = useCallback(async  (cancel:any)=> {

        let param = searchParams.get("appid");
        console.log(window.location.href)
        console.log(loc.pathname);
        console.log(param)
        if(param !== null) {
            await getAccessModes(param , cancel).then(res => {
                console.log(res)
                setApp(param!);
                setConfig(res);
            }).catch(e => {

            });
        }
    },[]);

    useEffect(()=>{
        console.log("in effect")
        if(controller.current !== undefined){
            void controller.current.cancel("axios");
        }
        controller.current = axios.CancelToken.source();
        void initData(controller.current.token);
    },[initData]);



    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value;
        let err:string[] = Object.assign([] as string[], errors);
        let loginReq = Object.assign({},loginRequest);

        switch (event.target.id) {
            case "email":

                err = err.filter((item:string) => item !== "invalidEmail" && item !== "enterCred" && item !== "wrongCred");
                loginReq.email = value;
                break;
            case "password":
                err = err.filter((item:string) =>  item !== "enterCred" && item !== "wrongCred");
                loginReq.password = value;
                break;

        }
        setLoginRequest(loginReq);
        setErrors(err);
    }

    async function loginLocal() {
        setLoading(true);
        let payload = Object.assign({}, loginRequest);

        if(payload.email !== "" && isCorrectEmail(payload.email) && payload.password !== "") {

            await login({email: payload.email, password: payload.password, username: ""}, app!).then(res => {

                window.location.href=portalUrl+config!.prefix+"/"+config!.callback+"?token="+res.accessToken+"&info="+res.silentToken;
            }).catch(e => {
                navigate("/oauth2/error?error="+e)

            });

        }else{
            let err = Object.assign([], errors);
            err.push("enterCred");
            setErrors(err);
            setLoading(false);
        }
    }


    return (
        <BoxMain>
            {config !== undefined ?
            <Box sx={{
                backgroundImage: "url(" + cic + ")",
                width: "100%",
                backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "left top",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            }}>
                <Stack direction={"row"} justifyContent={"space-between"} sx={{width:"100%"}}>
                    <Stack justifyContent={"center"} alignItems={"center"} spacing={3} sx={{maxWidth:800, paddingLeft:theme.spacing(8)}}>
                <Typography variant={"h3"} sx={{color:"white", fontWeight:800,paddingTop:theme.spacing(8)}}>Client Innovation Center</Typography>
                    <Typography variant={"h3"} sx={{color:"white", fontWeight:800}}>Portal</Typography>
                    </Stack>
                    <img src={logo} width={200} height={70} style={{margin:theme.spacing(8)}}/>
                </Stack>

                <Box justifyContent={"flex-start"} alignItems={"center"} sx={{display:"flex",flexDirection:"column", width:"100%", height:"100%", paddingTop:theme.spacing(8)}}>

                    <Paper sx={{padding:theme.spacing(5)}} elevation={10}>

                        {config.accessEnum.includes("local") ?
                    <Stack spacing={3} sx={{maxWidth:400}} justifyContent={"center"} alignItems={"center"}>
                        <Stack spacing={2} sx={{maxWidth:400}}>
                            <Typography  sx={{color:"primary.main", fontWeight:600}}>Login with a NTT CIC account</Typography>
                            {errors.includes("wrongCred")  ?
                                <Typography variant={"body1"}
                                            style={{ color: red, alignSelf:"flex-start"}}>
                                  email
                                    </Typography> : null}

                            <TextField sx={{minWidth:400, backgroundColor:theme.palette.background.default, borderRadius:1}}
                                       required
                                        id="email"
                                       helperText={errors.includes("invalidEmail") ?   "wrong email": null}
                                       placeholder={  i18next.t("tfEmail",{ns:"home"})}
                                       onChange={onChange}
                                       onBlur={()=>{
                                           if(loginRequest.email !== "" && !isCorrectEmail(loginRequest.email)){
                                               let err = Object.assign([], errors);
                                               err.push("invalidEmail");
                                               setErrors(err)
                                           }
                                       }}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                            />


                            <form style={{width:"100%"}}>
                            </form>
                            <TextField id="password" value={loginRequest.password} sx={{minWidth:400, backgroundColor:theme.palette.background.default, borderRadius:1}}
                                       required
                                       variant={'outlined'}

                                       placeholder={ "psw"}
                                type={showPsw.includes("password") ? "text" : "password"}
                                 autoComplete={"password"}
                                //     className={classes.tfMedium}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end" ><IconButton size={"small"}
                                                                                                     style={{
                                                                                                         //         backgroundColor: "white",
                                                                                                         padding: 0
                                                                                                     }}
                                                                                                     onClick={()=>{
                                                                                                         let array = [...showPsw];
                                                                                                         if(array.includes("password")){
                                                                                                             array = array.filter(a => a !== "password");
                                                                                                         }else{
                                                                                                             array.push("password")
                                                                                                         }
                                                                                                         setShowPsw(array);
                                                                                                     }}>

                                               {showPsw.includes("password") ?
                                                   <Visibility style={{fontSize: 23, color: iconGrey}}/>
                                                   : <VisibilityOff style={{fontSize: 23, color: iconGrey}}/>
                                               }
                                           </IconButton></InputAdornment>,
                                       }}


                                       onChange={onChange}
                            />

                        </Stack>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    void loginLocal()
                                }}
                                size={"large"}
                                sx={{borderRadius:5, width:400}}
                            >
                                Login
                            </Button>
                        <Typography style={{fontSize:18}} sx={{color:"primary.main", fontWeight:700, paddingTop:theme.spacing(2) }}>OR</Typography>
                    </Stack> : null}

                        <Typography>{config.accessEnum + " " + config.prefix + " " +config.callback}</Typography>
                            <Typography  sx={{display:!config.accessEnum.includes("local")? "flex":"none", color:"primary.main", fontWeight:600, paddingBottom:theme.spacing(3)}}>Only login with Microsoft is available  </Typography>

                    <Button
                        variant={"contained"}
                        size={"large"}
                        //  style={{background: 'white', color: '#004ba0', minWidth: 300}}
                        sx={{display:config.accessEnum.includes("sso") ? "flex" : "none", borderRadius:5, width:"100%", maxWidth:400, marginTop:  theme.spacing(5) }}
                        startIcon={<MicrosoftIcon  />}
                        href={baseUrl + "/oauth2/authorize/azure?redirect_uri=" + portalUrl+config.prefix+"/"+config.callback+"/"+app}
                    >
                        Login with Microsoft
                    </Button>
                    </Paper>
                </Box>

            </Box> : <Typography>no login</Typography>}

        </BoxMain>

    )
}