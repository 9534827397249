import React, {useCallback, useEffect, useState} from 'react';
import {getAccessToken} from "./Auth2UserAPI";
import {setAuthenticated, setUser} from "../../Commons/GeneralSlice";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Box, Button, IconButton, InputAdornment, Paper, Stack, TextField, Typography} from "@mui/material";
import {BoxMain} from "../../StyledComponents/Boxes";
import cic from "../../Images/cic.png";
import {theme} from "../../Themes/globaltheme";
import logo from "../../Images/logo_white.png";
import {iconGrey, red} from "../../Commons/Settings";
import i18next from "i18next";
import {baseUrl, isCorrectEmail, portalUrl} from "../../Utils/UtilsConstants";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ReactComponent as MicrosoftIcon} from "../../Images/microsoft-icon.svg";

export default function OAuth2Handler (props:any) {




    return (
        <BoxMain>

                <Box sx={{
                    backgroundImage: "url(" + cic + ")",
                    width: "100%",
                    backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "left top",
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                }}>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{width:"100%"}}>
                        <Stack justifyContent={"center"} alignItems={"center"} spacing={3} sx={{maxWidth:800, paddingLeft:theme.spacing(8)}}>
                            <Typography variant={"h3"} sx={{color:"white", fontWeight:800,paddingTop:theme.spacing(8)}}>Client Innovation Center</Typography>
                            <Typography variant={"h3"} sx={{color:"white", fontWeight:800}}>Portal</Typography>
                        </Stack>
                        <img src={logo} width={200} height={70} style={{margin:theme.spacing(8)}}/>
                    </Stack>

                    <Box justifyContent={"flex-start"} alignItems={"center"} sx={{display:"flex",flexDirection:"column", width:"100%", height:"100%", paddingTop:theme.spacing(8)}}>

                        <Paper sx={{padding:theme.spacing(5), maxWidth:500}} elevation={10}>
                            <Typography style={{lineHeight:2}} sx={{color:"primary.main", fontWeight:600}}>You do not have access to this application. Please contact the Client Innovation Center team to give acquire a login.</Typography>
                        </Paper>
                    </Box>

                </Box>

        </BoxMain>

    )



}

