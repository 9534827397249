import {Box, BoxProps, styled, Typography} from "@mui/material";
import {theme} from "../Themes/globaltheme";
import React from "react";
import {bcgGrey, darkGrey} from "../Commons/Settings";


export const BoxMainPage = styled(Box)<BoxProps>(({ theme }) => ({
    height: "calc(100vh - 60px)",
    [theme.breakpoints.up("md")]: {
        height: "calc(100vh - 60px)",
        marginTop: 60,

    },
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    marginTop: 60,
}));


export const BoxMainPageDetail = styled(Box)<BoxProps>(({ theme }) => ({
    height: "calc(100vh - 60px)",
    [theme.breakpoints.up("md")]: {
        height: "calc(100vh - 60px)",
        marginTop: 60,

    },
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"center",
    marginTop: 60,
}));


export const BoxMain = (props:BoxProps) => {
    return <Box {...props} sx ={{
        width:{xs:"100%", md:"100%", lg:"100%"}, minHeight:"100vh", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",
    }}>

        {props.children}

    </Box>
};

interface BoxPanel extends BoxProps{
    smallWidth?:number
}

export const BoxScreen2Panels = (props:BoxPanel) => {
    return <Box {...props} sx ={{
        flexDirection: "row", height: "100%",
        width:  props.smallWidth ? props.smallWidth :   "100vw",
        maxWidth:750,
        marginRight:theme.spacing(1),
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2),
        position:"relative",
            [theme.breakpoints.down("md")]: {
                width:"100vw"
            }
       }}>
        {props.children}
    </Box>
};

export interface CircleOverviewProps extends BoxProps{
    low?: string
    title:string
}

export const CircleBox  = (props:CircleOverviewProps) =>{
    return <Box {...props} sx={{ cursor:"pointer", "&:hover": {borderColor:"primary.light"}, marginRight:10, marginTop:props.low? 10:undefined, borderRadius:40, border:"solid",
        borderWidth:30, borderColor:theme.palette.primary.main, height:230, width:230, alignItems:"center", justifyContent:"center", display:"flex"}}>
        <Typography style={{fontSize:30}} sx={{fontWeight:500, color:"primary.main"}}>{props.title}</Typography>
    </Box>
}


export const BoxScreenLgPanel = styled(Box)<BoxProps>(({ theme }) => ({
    flexDirection: "row", height: "100%",
    width: 1200,
    paddingLeft:  theme.spacing(3),
    paddingRight:  theme.spacing(3),
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    [theme.breakpoints.only("md")]: {
        width:"calc(100vw - 70px)",
        paddingLeft: theme.spacing(2),
        paddingRight:theme.spacing(2)
    },
    [theme.breakpoints.down("md")]: {
        width:"100vw",
        paddingLeft:0,
        paddingRight:0
    }
}));


export const BoxPanelHeader = styled(Box)<BoxProps>(({ theme }) => ({
    padding: theme.spacing(1),
  //  backgroundColor: theme.palette.primary.main,
    //borderRadius: 2,
    // margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height:45,
    width:"100%",

    [theme.breakpoints.down("md")]: {
        display:"none"
    }
}));

//--------- modals ----------------------------------






export const BoxPanelHeaderShowSm = styled(Box)<BoxProps>(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 2,
    // margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height:45,
    [theme.breakpoints.down("sm")]: {
        display:"none"
    }
}));


// ------------------------- detail day -----------------------------

export const BoxModalBody = (props:BoxProps)=>{
    return   <Box {...props} sx={{    paddingTop:theme.spacing(2),
        display:"flex", flexDirection:"column", width:"100%", justifyContent:"flex-start", alignItems:"center", height:"100%", backgroundColor:"white",
    }}>
        {props.title ?
            <Typography sx={{
                color:darkGrey,
                fontSize:16,
                fontWeight:700,
                alignSelf:"flex-start",
                marginLeft:theme.spacing(2)
            }}>{props.title}</Typography>
            : null}
        {props.children}
    </Box>
};


