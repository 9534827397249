import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { ResultLoginUserInfoPayload } from "../Pages/Login/LoginModels";
import {GeneralState} from "./ReduxModels";

const initialState : GeneralState = {
    user:undefined,
    authenticated:false,
    adminMode:false
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setUser: (state, action:PayloadAction<ResultLoginUserInfoPayload|undefined>) => {
            state.user =  action.payload
        },
        setAuthenticated: (state, action:PayloadAction<boolean>) => {
            state.authenticated =  action.payload
        },
        setAdminMode: (state, action:PayloadAction<boolean>) => {
            state.adminMode =  action.payload
        },
    },
});

export const {setUser, setAuthenticated,setAdminMode} = generalSlice.actions;

export default generalSlice.reducer;