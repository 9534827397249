import validate from "validate.js";

//export const baseUrl = "https://www.innoty-rest-001.be";
//export const baseUrl = "https://innoty-backend.azurewebsites.net";

//export const baseUrl = "https://cicdemo.westeurope.cloudapp.azure.com/api";
//export const baseUrl = "http://51.124.13.183/api";
//export const baseUrl="https://77.241.90.178:8060";
//export const baseUrl = "http://172.16.4.229:8060";


/*

export const baseUrl = "http://localhost:8060";

export const portalUrl = "http://localhost:3002/"
*/

//azure aks endpoints

export const baseUrl= "https://cicportal.westeurope.cloudapp.azure.com/oauthapi";
export const portalUrl= "https://cicportal.westeurope.cloudapp.azure.com/"





const constraints = {from: {email: true}};

export function isCorrectEmail(email: string) {
    return validate({from: email}, constraints) === undefined;
}
