import {AppConfig, LoginAnswer, LoginRequest} from "./LoginModels";
import APIConstants from "../../APIConstants";
import {AxiosResponse} from "axios";
import {axiosBase} from "../../Utils/AxiosAuth";

export async function login(loginRequest:LoginRequest, appId:string){
    let result:LoginAnswer = {} as LoginAnswer;

    await APIConstants.post("auth/login/"+appId,loginRequest).then((res: AxiosResponse<LoginAnswer>) => {
        result= res.data
    });
    return result
}

export async function getAccessModes(appid:string, cancel:any){
    let result:AppConfig = {} as AppConfig;
    await APIConstants.get("/auth/app/"+appid, {cancelToken:cancel}).then((res: AxiosResponse<AppConfig>) => {
        result= res.data
    });
    return result;
}


// ------------------------------------- psw forgotten ------------------------------------------------------
/*

export async function onPswStep1(email:string){
    let emailHolder = {email: email};

    let result:string = "";
    await APIConstants.post("auth/psw1",emailHolder).then((res: AxiosResponse<string>) => {
        result= res.data;
    });
    return result;
}

export async function onPswStep2ValidateCode(data:{data:string,hash:string}){
    let result:string = "";
    await APIConstants.post("auth/code",data).then((res: AxiosResponse<string>) => {
        result= res.data;
    });
    return result
}
export async function onUpdatePassword(data:{data:string,hash:string}){
    await APIConstants.post("auth/update",data)
}*/
