import React from 'react';
import './App.css';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Navigate, Route, Routes} from "react-router";
import LoginPage from './Pages/Login/LoginPage';
import OAuth2Handler from "./Pages/Login/Oauth2Handler";
import Err404 from "./Pages/Login/Err404";
import {useLocation} from "react-router-dom";


export default function App(){

    const loc = useLocation();

    return (


      <LocalizationProvider dateAdapter={AdapterMoment}>

          {console.log(loc.pathname)}
        <Routes>

       <Route  path="oauth/login"
                  element={<LoginPage/>}/>


            <Route path="oauth/oauth2/error" element={<OAuth2Handler />}
                  />

          <Route path="*" element={<Err404/>} />

        </Routes>

      </LocalizationProvider>




  );
}
