import {BoxMain} from "../../StyledComponents/Boxes";
import React from "react";
import {Typography} from "@mui/material";


export default function Err404() {


    return (
        <BoxMain>
            <Typography>err 404</Typography>

        </BoxMain>

    )
}