

import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./Themes/globaltheme";
import React from "react";

import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import {store} from "./store";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
ReactDOM.render(
    <Provider store={store}>
            <ThemeProvider theme={theme }>
                    <CssBaseline/>
                <BrowserRouter >
                    <App /></BrowserRouter></ThemeProvider></Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
